<template>
  <v-col cols="12">
    <v-card class="mt-1">
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="px-8 d-flex"
        >
          <v-text-field
            v-model="search"
            rounded
            dense
            outlined
            placeholder="Search"
            class="app-bar-search"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="px-8 d-flex"
        >
          <v-combobox
            v-model="searchTags"
            outlined
            :items="tags"
            rounded
            hide-details
            dense
            label="Tags"
            multiple
            small-chips
          >
          </v-combobox>
          <v-btn
            color="primary"
            rounded
            class="ml-4"
            :loading="loading"
            @click="loading ? () => {} : searchItem()"
          >
            <v-icon size="22">
              {{ icons.mdiMagnify }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          offset-md="6"
          md="6"
        >
          <v-pagination
            v-model="page"
            circle
            :length="pages"
            :total-visible="5"
            @input="changePage"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-4">
      <v-simple-table
        class="table-data"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Title
              </th>
              <th class="text-center text-uppercase">
                Length Seconds
              </th>
              <th class="text-center text-uppercase">
                Type
              </th>
              <th class="text-center text-uppercase">
                Lang
              </th>
              <th class="text-center text-uppercase">
                MP3
              </th>
              <th class="text-center text-uppercase">
                Text
              </th>
              <th class="text-center text-uppercase">
                Processing Status
              </th>
              <th class="text-center text-uppercase">
                Tags
              </th>
              <th class="text-center text-uppercase">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.title }}</td>
              <td class="text-center">
                <span v-if="item.length_seconds != null">{{ item.length_seconds }} sec.</span>
              </td>
              <td class="text-center">
                {{ item.type }}
              </td>
              <td class="text-center">
                {{ item.lang }}
              </td>
              <td class="text-center">
                <a
                  v-if="item.mp3_url && (item.mp3_url !== null || item.mp3_url !== '')"
                  :href="item.mp3_url"
                  target="_blank"
                > open </a>
              </td>
              <td class="text-center">
                <a
                  v-if="item.result_url && (item.result_url !== null || item.result_url !== '')"
                  @click="download(item.result_url, `${item.title}.txt`)"
                > download </a>
              </td>
              <td class="text-center">
                {{ item.processing_status }}
              </td>
              <td>
                <v-chip-group
                  active-class="primary--text"
                >
                  <v-chip
                    v-for="(tag, index) in item.tags"
                    :key="`${tag}-${index}`"
                    color="primary"
                    small
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </td>
              <td class="text-center">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiDotsVertical }}
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="$router.push({ name: 'detail', params: { id: item.id }})">
                      <v-list-item-title>Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$router.push({ name: 'update', params: { id: item.id }})">
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiMagnify, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import TrackProvider from '@/resources/track'

const TrackService = new TrackProvider()

export default {
  data() {
    return {
      page: 1,
      pages: 1,
      perPage: 25,
      loading: false,
      search: '',
      sortBy: 'createdAt',
      sortOrder: 'desc',
      items: [],
      searchTags: [],
      tags: []
    }
  },
  computed: {
    queryPage() {
      return this.$route.query.page ? +this.$route.query.page : 1
    },
    querySearch() {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    queryTags() {
      return this.$route.query.tags ? this.$route.query.tags : '[]'
    }
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiDotsVertical
      }
    }
  },
  created() {
    if (!this.$route.query.page || !this.$route.query.search || !this.$route.query.tags) {
      this.$router.replace({
        name: 'trackList',
        query: {
          page: this.$route.query.page ? this.$route.query.page : '1',
          search: this.$route.query.search ? this.$route.query.search : '',
          tags: this.$route.query.tags ? this.$route.query.tags : JSON.stringify([])
        }
      }, () => {})
    }
    this.page = this.queryPage
    this.search = this.querySearch
    this.searchTags = JSON.parse(this.queryTags)
    this.getData()
  },
  methods: {
    async download(url, fileName) {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob'
      })
      const dataUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = dataUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    searchItem() {
      this.page = 1
      this.$router.push({
        name: 'trackList',
        query: {
          page: '1',
          search: this.search,
          tags: JSON.stringify(this.searchTags)
        }
      }, () => {})
      this.getData()
    },
    changePage() {
      this.$router.push({
        name: 'trackList',
        query: {
          page: this.page,
          search: this.$route.query.search ? this.$route.query.search : '',
          tags: this.$route.query.queryTags ? this.$route.query.queryTags : '[]'
        }
      }, () => {})
      this.getData()
    },
    async getData() {
      this.loading = true
      try {
        const tags = await TrackService.getTags()
        this.tags = tags.data
        const query = {
          page: this.queryPage,
          per_page: this.perPage,
          search: this.querySearch,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          tags: this.searchTags
        }
        const { data } = await TrackService.getTrack(query)
        this.pages = data.pages
        this.items = data.results
      } catch (err) {
        this.$store.dispatch('Style/setSnackbar', {
          message: err.message,
          type: 'pink',
          active: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.table-data {
  height: calc(100vh - 320px);
  overflow: auto;
}

@media (min-width: 960px)  {
  .table-data {
    height: calc(100vh - 265px);
    overflow: auto;
  }
}
</style>
